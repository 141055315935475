body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.trunche{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   -webkit-box-orient: vertical
}
.conter{
  color: white;
      display: inline-block;
      background: #1E93E4;
      /* width: 20px; */
      /* height: 23px; */
      text-align: center;
      font-size: 19px;
      font-weight: 900;
      padding: 10px;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 24px;
      padding-left: 15px;
      padding-right: 15px;

}
.priceList{
  text-align: right;
    margin-right: 11px;
    font-weight: 900;
}
.bp3-drawer-header{
  box-shadow: none
}
.form-control{
    width: 100px
}
.drag{
  border: 2px dashed #ddd;
padding: 32px;
color: #aba9a9;
margin-top: 20px;
text-align: center;
}
