:root {
  --color-surface: #fff;
  --color-solid: black;
  --color-primary: #00ccbc;
  --color-action: #ffeae4;
  --color-secondary: teal;
  --color-sfondo: #eee;
  --color-greem: #1ebed5;
  --color-orange:#f67d01;
  --color-fuxia:#dc004c;
  --color-yellow:#f7de00;
  --color-mattone:#f63b3b;
  --color-gray:#889797
}

body{
  background: var(--color-sfondo);
  color: var(--color-gray:#889797)
}

.App {
  text-align: center;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
outline: none;
box-shadow: 0 0 0 1px #ffffff00, 0 0 0 3px rgba(19, 124, 189, 0), inset 0 1px 1px rgba(16, 22, 26, 0)
}
h3{
  margin: 0;
}
.slotDisp{
  position: absolute;
z-index: 10;
background: white;
left: 50%;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.barra{
  min-height: 50px;
  padding-bottom: 20px
}
.container{

}
.containerFix{
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-surface)
}
.grid02{
  display: grid;
  grid-template-columns: 400px auto
}
.info{
  padding: 20px
}
.restaurant{
  position: relative;
  z-index: 1
}
.boxOption input{
  border: 1px solid #eee
}
.specialBar{
  height: 300px;
  position: absolute;
  width: 100%;
  background: var(--color-primary);
}
.barraCategorie nav{
  flex-wrap: wrap;
  overflow-x: scroll;
  display: flex;
}
.categoriaDiv{
  padding: 10px
}
.cassa{
  flex: 0 0 33%;
  background: red;
  position: relative;
  display: flex;
}
.boxlist{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 15px
}
.f1{
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
      overflow: hidden;
}
.cardList{
  background: var(--color-surface);
  padding: 16px
}
.bigInfo{
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.BoxInfo{
    display: grid;
    grid-template-columns: 60% 30%;
    grid-column-gap: 10px
}

.piattoList p{
  margin-right: 40px
}
.newtag{
  background: var(--color-primary);
  color: white;
  padding: 10px;
  margin: 0px;
  border-radius: 20px;
  height: 56px;
}
.newtag h2{
  margin: 0px
}
.actionButton{
  background: var(--color-greem);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
}
.actionButton:hover{
  background: var(--color-primary);
}

.title{
  padding: 14px;
font-size: 27px;
font-weight: 900;
font-family: arial;
letter-spacing: -2px;
color: var(--color-mattone);
margin-top: -34px;
margin-bottom: -81px;
}
#title{
  padding: 14px;
font-size: 27px;
font-weight: 900;
font-family: arial;
letter-spacing: -2px;
color: var(--color-mattone);
margin-top: -34px;
margin-bottom: -81px;
}

a:hover{
  text-decoration: none
}
.overAct{
  background: #fff
}
.overAct:hover{
  background: #f7f7f7
}
.nowTime{
  background: #fcbf49
}
.nowTime:hover{
  background: #fcbf49;
  opacity: 0.8;
}
.notNow{
  background: #2a9d8f
}
.notNow:hover{
  background: #2a9d8f;
  opacity: 0.8;
}
.layoutApp{
  display: grid;
grid-template-columns: 200px auto;
height: 100vh;
}

.boxHome{
  height: 100%;
  position: relative;
  padding: 0px;
display: grid;
grid-template-columns: 350px auto;
gap: 20px;
}
.cardss{
  width:300px
}
.gridWEb{
  display: grid;
grid-template-columns: 250px auto;
margin-top: 20px;
column-gap: 5%;
}
.listtt a button {
  color: white
}
.flex{
  max-width: 900px;
margin-left: auto;
margin-right: auto;
display: flex;
}
.x30c{
  width:30%
}
.desckTop{
  display: block;
}
.mobileP{
  display: none;
}

.x70c{
  width: 70%;
}
.modals{
  /*min-width: 800px;*/
  max-height: 70vh;
  overflow-y: scroll;
}

.conterDw{
  display: inline-block;
    background: #efefef;
    padding: 12px;
    border-radius: 29px;
    padding-top: 15px;
    padding-bottom: 15px;
}


.tagFas{
  background: #fcc157;
    display: inline-block;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 700;
    margin-right: 5px;
}


.colorStatus{
  background: #57aefc
}
.scrollMega{
  position: relative;
    z-index: 2;
    overflow: scroll;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
/* Styles */
.boxHome{
display: block;

}
.scrollMega{
  position: relative;
    z-index: 2;
    overflow: auto;
}
.slotDisp{
  position: fixed;
    z-index: 10;
    background: #ffffff;
    left: 50%;
    border-radius: 10px;
    /* border-bottom-right-radius: 10px; */
    bottom: 9px;
}
.desckTop{
  display: none;
}
.menuM{
  margin-top: 58px;
    margin-right: 24px;
    color: white;
    background: #334f5d;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
}

.tagFas{
  background: #fcc157;
    display: inline-block;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    color: white;
    font-weight: 700;
}

.mobileP{
  display: block;
}

.x70c{
  width: 100%;
}
.x30c{
  width:100%
}
.flex{

display: block;
}
.gridWEb{
  display: block;
}
.cardss{
  width:100%
}

.layoutApp{
  display: block;
height: 100vh;
}
.BoxInfo{
  display: block;
}
h1{
  font-size: 1.5em
}
.bigInfo{
  padding: 8px
}

}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
/* Styles */

}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
/* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
/* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}
/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
/* Styles */
}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}
